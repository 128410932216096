
 
import { Typography, Icon, Stack, ToggleButton, ToggleButtonGroup } from "@suid/material";
import { BikeScooterOutlined } from "../icons";
import { getText } from '../SmallComponents/Translate'
import { isExtraSmall }  from "../../../utils/sizeUtil"


interface FieldWorkModelProps {
    workModel: string;
    remoteWork: string;
    setWorkModel: (newValue: string) => void;
    setRemoteWork: (newValue: string) => void;
}
const FieldWorkModel = (props: FieldWorkModelProps) => {

    const handleInputChange = (key: string, value: string) => {
        const setValue = props[key as keyof FieldWorkModelProps];

        if (typeof setValue === "function") {
            const setValue = props[key as keyof FieldWorkModelProps] as ((newValue: string) => void) | undefined;
            if (setValue) {
                setValue(value);
            }
        } else {
            const setValue = props[key as keyof FieldWorkModelProps] as ((newValue: string) => void) | undefined;
            if (setValue) {
                setValue(value);
            }

            //  throw new Error(`Tried to set prop "${key}", but it's not a function. Got: ${setValue}`);
        }
    };
    const txtWorkModel = getText("smallcomponent", "workmodel")
    const txtOnsite = getText("smallcomponent", "onsite")
    const txtRemote = getText("smallcomponent", "remote")
    const txtRemoteWork = getText("smallcomponent", "remotework")
    const txtHybrid = getText("smallcomponent", "hybrid")
    return (
        <>
            <Stack spacing={1}>
                <Stack direction={isExtraSmall() ?  'column'  : 'row' }>
                    <Typography sx={{ px: 2 }} variant="body1" gutterBottom>  {txtWorkModel()} </Typography>
                    <ToggleButtonGroup
                        color="primary"
                        sx={{ px: 2 }}
                        value={props.workModel}
                        size="small"
                        exclusive

                        onChange={(event, value) => {
                            handleInputChange("setWorkModel", value);
                        }}
                    >

                        <ToggleButton value="Hybrid">{txtHybrid()}</ToggleButton>
                        <ToggleButton value="Onsite">{txtOnsite()}</ToggleButton>
                        <ToggleButton value="Remote">{txtRemote()}</ToggleButton>

                    </ToggleButtonGroup>
                </Stack>
                <Stack direction={isExtraSmall() ?  'column'  : 'row' }>
                    <Typography sx={{ px: 2 }} variant="body1" gutterBottom>  {txtRemoteWork()} </Typography>
                    <ToggleButtonGroup
                        color="primary"
                        sx={{ px: 2 }}
                        value={props.remoteWork}
                        size="small"

                        onChange={(event, value) => {
                            handleInputChange("setRemoteWork", value);
                        }}
                    >

                        <ToggleButton value="1"><Icon><BikeScooterOutlined /></Icon></ToggleButton>
                        <ToggleButton value="2"><Icon><BikeScooterOutlined /></Icon></ToggleButton>
                        <ToggleButton value="3" ><Icon><BikeScooterOutlined /></Icon></ToggleButton>
                        <ToggleButton value="4"><Icon><BikeScooterOutlined /></Icon></ToggleButton>
                        <ToggleButton value="5"><Icon><BikeScooterOutlined /></Icon></ToggleButton>
                    </ToggleButtonGroup>
                    <Typography sx={{ px: 2 }} variant="body1" gutterBottom>  3 days onsite/2 days remote </Typography>
                </Stack>
            </Stack>
        </>
    )
}
export {  FieldWorkModel }